import React from 'react';
import { Icon } from '@iconify/react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Menu from '../components/navigation';
import FullImage from '../components/full-image';

/**
 * Page
 */
const KontaktPage = () => {
	return (
		<Layout page="kontakt" image="kontakt">
			<SEO title="" />
			<Menu selected="kontakt" />
			<div className="page-width align-center spacing-normal spacing-huge-top">
				<p className="text-subheadline text-subheadline--bold">
					WILLKOMMEN
				</p>
				<p>
					Bei einem ersten kostenlosen Kennenlernen besprechen wir,
					welche Werte Sie und Ihr Unternehmen ausmachen. Darauf
					aufbauend erstelle ich Ihnen ein individuelles Angebot. Ich
					freue mich auf Ihre Terminanfrage!
				</p>
				<div className="contact-list">
					{/* <div>
						<Icon icon="vb:kontakt1" height="auto" />
						<p>
							Haldystraße 1 b<br />
							66123 Saarbrücken
						</p>
					</div> */}
					<div>
						<Icon icon="vb:kontakt2" height="auto" />
						<p>T 0176 63 14 34 61</p>
					</div>
					<div>
						<Icon icon="vb:kontakt3" height="auto" />
						<p>vb@vb-grafikdesign.de</p>
					</div>
				</div>
			</div>

			<div className="page-width align-center spacing-normal big-logo">
				<Icon icon="vb:big-logo" height="auto" />
				<Icon icon="vb:big-logo-text" height="auto" />
			</div>

			{/* <div className="mapouter">
				<div className="gmap_canvas">
					<iframe
						width="100%"
						height="500"
						src="https://maps.google.de/maps?q=Haldy%20Str.%201%20b%2066123%20Saarbr%C3%BCcken&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
						frameborder="0"
						scrolling="no"
						marginheight="0"
						marginwidth="0"
						title="Map"
					></iframe>
				</div>
			</div> */}
			<FullImage image="kontakt-footer" />
		</Layout>
	);
};

export default KontaktPage;
